import React, { Component } from 'react';

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.vidRef = React.createRef();
    this.playBtnRef = React.createRef();
    this.resetBtnRef = React.createRef();
    this.playVideo = this.playVideo.bind(this);
    this.endVideo = this.endVideo.bind(this);
    this.resetVideo = this.resetVideo.bind(this);
  }
  playVideo() {
    if( this.refs.vidRef.currentTime !== 0 ){
      this.refs.resetBtnRef.classList.add('show');
      this.refs.resetBtnRef.classList.remove('hide');
    }
    if (this.refs.vidRef.paused === false) {
        this.refs.vidRef.pause();
        this.refs.playBtnRef.classList.add('show');
        this.refs.playBtnRef.classList.remove('hide');
    } else {
        this.refs.vidRef.play();
        this.refs.playBtnRef.classList.add('hide');
        this.refs.playBtnRef.classList.remove('show');
        this.refs.resetBtnRef.classList.add('hide');
        this.refs.resetBtnRef.classList.remove('show');
    }
  }
  endVideo() {
    this.refs.playBtnRef.classList.add('show');
    this.refs.playBtnRef.classList.remove('hide');
    this.refs.resetBtnRef.classList.add('hide');
    this.refs.resetBtnRef.classList.remove('show');
  }
  resetVideo() {
    this.refs.vidRef.currentTime= 0;
    this.playVideo();
  }

  render() {
    const source = this.props.src;
    return (
      <React.Fragment>
        <div
          className="video"
          onClick={this.playVideo}>
          <video
            ref="vidRef"
            src={source}
            type="video/mp4"
            onEnded={this.endVideo} />
          <div
            className="resetVideo"
            ref="resetBtnRef"
            onClick={this.resetVideo} />
          <div
            className="playButton"
            ref="playBtnRef" />
        </div>
      </React.Fragment>
    );
  }
}

export default VideoPlayer;
