import React, { Component } from 'react';
import PHPPlugins from '../Projects/ProjectTemplates/phpPlugins.js';
import Maps from '../Projects/ProjectTemplates/maps.js';
import PriceTags from '../Projects/ProjectTemplates/priceTags.js';
import DisplayAdmin from '../Projects/ProjectTemplates/displayAdmin.js';
import AnyDecision from '../Projects/ProjectTemplates/anyDecision.js';
import OcFl from '../Projects/ProjectTemplates/ocfl.js';
import './template.css';

class Template extends Component {

    openDrawer(e) {
      const ev = new Event("click", { bubbles: true});
      document.querySelector('.closeButton').dispatchEvent(ev);
      setTimeout(function(){
        document.querySelector('.project-link-wrap > li:first-child').dispatchEvent(ev);
      }, 500)
      setTimeout(function(){
        document.querySelector('#'+e).dispatchEvent(ev);
      }, 500)
    }

    render () {
      const title = this.props.title;
      let related = this.props.related;
      let pluginInfo= '';
      switch (title) {
        case 'PHP Plugins':
          pluginInfo= <PHPPlugins />;
          break;
        case 'Maps':
          pluginInfo= <Maps />;
          break;
        case 'Digital Price Tags':
          pluginInfo= <PriceTags />;
          break;
        case 'Display Admin':
          pluginInfo= <DisplayAdmin />;
          break;
        case 'Any Decision':
          pluginInfo= <AnyDecision />;
          break;
        case 'OCFL':
          pluginInfo= <OcFl />;
          break;
        default:
          pluginInfo= <PHPPlugins />;
          break;
      }

      return (
        <div className="projectWrap">
        <h2>{title}</h2>
        {pluginInfo}
        <section className="relatedProjects">
        <hr />
        <h3 className="projectsTitle">Other Projects</h3>
        {related.map((value, index) => {
          return <div
          key={index}
          onClick={() => this.openDrawer(value)}>
          [ {(value.charAt(0).toUpperCase() + value.slice(1)).replace( /([A-Z])/g, " $1" )} ]
          </div>
        })}
        </section>
        </div>
      )
    }
  }

  export default Template;
