import React, { Component } from 'react';
import EarlySketch from './Maps-Early-Sketch.jpg';
import EarlyDetail from './/Maps-Early-Detail.jpg';
import EarlyInteraction from '../../../videos/Maps-Early-Interaction.mp4';
import EarlyFilterConcept from '../../../videos/Maps-Early-Filter-Concept.mp4';
import MapsVersionOne from '../../../videos/Maps-Version-One.mp4';

import Photograph from '../../Photograph/photograph.js';
import VideoPlayer from '../../VideoPlayer/video.js';

class Maps extends Component {

    render () {
      return (
        <React.Fragment>
          <p><strong>Need:</strong> With the building of a new five-story retail space, the owner wanted dynamic wayfinding maps to help customers navigate the ever changing retail floors.</p>
          <p><strong>Solution:</strong> Wall mounted touch screen monitors with Intel Nucs running the Porteus Kiosk OS locked into a web browswer. Each map location is uniquely loaded using URL parameters.</p>
          <p><strong>Process:</strong> An early draft of the map idea was drawn on paper to get approval from stakeholders before coding.</p>
          <Photograph url={EarlySketch} rotate="-1" caption="Early Sketch of Map Layout" />
          <Photograph url={EarlyDetail} rotate="1" caption="Detail Sketch of Bottom Drawer" />
          <p>No color was introduced in the early interation concepts to avoid getting too attached to the starting design. The very first idea for navigating each floor was to show all of them on screen as tilted cards and put an emphasize on the floor the user is actually standing on to orient them.</p>
          <VideoPlayer src={EarlyInteraction} />
          <p>Including each "Point of Interest" on the map at the bottom to highlight where a user can easily find them in the map  was an early idea. In addition, some way of either highlighting the floor a product categories was on in the building, or maybe shrouding where they weren't was considered.</p>
          <VideoPlayer src={EarlyFilterConcept} />
          <p>A screen saver was added to work as a reset for the screen between users (outside of avoiding potential screen burn in). A lot of testing was done in Responsive Mode for the purpose of easy. There was a late stage idea that became widely approved of to show a side filter that puts an actual dot where products were on the floor.</p>
          <VideoPlayer src={MapsVersionOne} />
          <p>More polish is added and the maps were added to the floor. Everyone product is added and each one highlights with its category on the filter. They also serve to show the floor plans of each floor more than the previous design only showing walls. Tapping on products creates a tooltip with the name of the product. The original store front is down the street, so the ability to see that store was included using CSS transitions and animations.</p>
        </React.Fragment>
      )
    }
  }

  export default Maps;
