import React, { Component } from 'react';
import OCFLNewsAppPage from './OCFL-News-App-Page.png';
import OCFLAlertAppPage from './OCFL-Alert-App-Page.png';
import OCFL311AppPage from './OCFL-311-App-Page.png';
import OCFLAtlasAppPage from './OCFL-Atlas-App-Page.png';
import OCFLNewsAppIcons from './OCFL-News-App-Icons.png';
import OrangeTVLiveButton from './OrangeTV-Live-Button.png';
import OCFLNewsNeeds1 from './OCFL-News-Page-Needs-1.png';
import OCFLNewsNeeds2 from './OCFL-News-Page-Needs-2.png';
import OCFLNewsSolved1 from './OCFL-News-Page-Solved-1.png';
import OCFLNewsSolved2 from './OCFL-News-Page-Solved-2.png';
import OCFLPageVideo from '../../../videos/OCFL-Pages-Video.mp4'

import Photograph from '../../Photograph/photograph.js';
import VideoPlayer from '../../VideoPlayer/video.js';

class OcFl extends Component {

    render () {
      return (
        <React.Fragment>
          <h3>Mobile App Page Template</h3>
          <p><strong>Need:</strong> Quickly design and develop a new page template for the collection of Orange County Florida government mobile apps.</p>
          <p><strong>Solution:</strong> A photoshop template with a smart layer to fill in screens on two phone illustrations was made to make a consistent look for each template to demonstrate a sample of the mobile app.</p>
          <p><strong>Examples:</strong></p>
          <Photograph url={OCFLNewsAppPage} rotate="1.5" caption="OCFL News App Page" />
          <Photograph url={OCFLAlertAppPage} rotate="-0.5" caption="OCFL Alert App Page" />
          <p>A title line was added next to the icon, and two paragraphs of explanation were slotted in between the app icon and the download buttons for both iOS and Android.</p>
          <Photograph url={OCFL311AppPage} rotate="-0.5" caption="OCFL 311 App Page" />
          <Photograph url={OCFLAtlasAppPage} rotate="1" caption="OCFL Atlas App Page" />


          <h3>Miscellaneous Pages</h3>
          <p><strong>Need:</strong> Stakeholders in different local government departments request page changes, site maps, wireframes, and user flows to update their section of the website.</p>
          <p><strong>Solution:</strong> Utilize strong organizational and interpersonal skills to work directly with clients to understand scope requirements and create new web pages and tools. Due to small team size, took responsible for creating the majority of assets, as well as coding and implementing the new webpages in the DNN CMS and WordPress. Code in HTML and CSS using flexbox as well as jQuery to organize the content in a clean and mobile-friendly design.</p>
          <VideoPlayer src={OCFLPageVideo} />

          <h3>News App Redesign</h3>
          <p><strong>Need:</strong> Work with iOS and Android teams to redesign the Orange County's news app to reflect <a target='_blank' rel="noopener noreferrer" href='https://newsroom.ocfl.net'>new website design</a> before State of the County where the updated newsroom website will be featured by the mayor.</p>
          <p><strong>Solution:</strong> Remove the shades of blue and the orange "swoosh" and replace the with shades of grey and off white. Update the app to be more geometric while incorporating rounded corners.</p>
          <p><strong>Process:</strong> The not-quite white background color is #F6F6F6 which comes from the WordPress website's background (probably based on the designers favorite function key) and the logo's text color is #FAAF40 which comes from a colored bar on the website. The OrangeTV button was originally a large banner on the bottom of the app, but with mobile app research it was decided the top right was a common place for Live TV buttons. The red banner was designed to resemble other banners that link to Live News.</p>
          <Photograph url={OCFLNewsAppIcons} rotate="1" caption="Old [Left] and New [Right] App&nbsp;Icons" />
          <Photograph url={OrangeTVLiveButton} rotate="-0.5" caption="OrangeTV Live Button" />
          <p>The typeface chosen for the iOS headings is called Bitter. The Android apps remained with the default sans serif font. Some of these changes were listed in an overview comparing the old and new designs.</p>
          <Photograph url={OCFLNewsNeeds1} rotate="-1" caption="Old OCFL News Page Design" />
          <Photograph url={OCFLNewsSolved1} rotate="0.5" caption="New OCFL News Page Design" />
          <p>The color of the text is #828282 which is the standard text color on the website so as not to be too bland with typical default #000 color.</p>
          <Photograph url={OCFLNewsNeeds2} rotate="1" caption="Old OCFL News Menu Design" />
          <Photograph url={OCFLNewsSolved2} rotate="-1.5" caption="New OCFL News Menu Design" />

        </React.Fragment>
      )
    }
  }

  export default OcFl;
