import React, { Component } from 'react';

class Photograph extends Component {
  static defaultProps = {
    url: "../Logo/Logo.png",
    rotate: "0",
    caption: ""
  };
  render() {
    const url = this.props.url;
    const rotate = this.props.rotate;
    const caption = this.props.caption;
    return (
      <React.Fragment>
        <div className="projectImage" style={{transform: 'rotate(' + rotate + 'deg)'}}>
          <img src={url} title={caption} alt={caption} />
          <div>{caption}</div>
        </div>
      </React.Fragment>
    );
  }
}

export default Photograph;
