import React, { Component } from 'react';
import Editor from './Any-Decision-Editor.jpg';
import Sorter from './Any-Decision.jpg';
import AnyDecisionDemo from '../../../videos/Any-Decision.mp4';

import Photograph from '../../Photograph/photograph.js';
import VideoPlayer from '../../VideoPlayer/video.js';

class AnyDecision extends Component {

    render () {
      return (
        <React.Fragment>
          <h3>Sort through your trickiest of lists. <a target='_blank' rel="noopener noreferrer" href='https://www.anydecision.com/'>See Live Website</a></h3>
          <p>Working from the concept of pairwise comparisons, this tool was designed and developed to help anyone make a decision by working through their list, two items at a time.</p>
          <Photograph url={Editor} rotate="-1" caption="List Editor Screenshot" />
          <Photograph url={Sorter} rotate="1" caption="Sorting Process Screenshot" />
          <p>This side project went through multiple rounds of agile development involving new features and more concise user flows.</p>
          <VideoPlayer src={AnyDecisionDemo} />
        </React.Fragment>
      )
    }
  }

  export default AnyDecision;
