import React, { Component } from 'react';
import OCFLPageVideo from '../../../videos/Wayfinder-Editor.mp4'

import VideoPlayer from '../../VideoPlayer/video.js';

class DisplayAdmin extends Component {

    render () {
      return (
        <React.Fragment>
          <h3>Display Admin</h3>
          <p><strong>Need:</strong> Give the ability to edit the digital maps around the retail store to the members of the team who change the displays in the store.</p>
          <p><strong>Solution:</strong> Custom iPad app for sale associates to update the location of products and points of interest on the maps. A desktop app was also made using Electron to force the shape into the dimensions of an iPad for consistency.</p>
          <p><strong>Process:</strong> This project came from the first time the maps needed to have all of the products added and, originally, they were all part of a JSON file with x and y coordinates. Realizing the inneficiency of manually updating the locations, an web page designed to fit an iPad was developed so that sales member would be able to walk around the store and position the displays properly on the maps. The type, position, and location of each display was then stored in a database.</p>
          <VideoPlayer src={OCFLPageVideo} />
          <p>The user interactive abilities were well thought out and tested with sales members. Once a display is created, the type can be set as well as a name. If the type is one of the "Product Displays" instead of a "Point of Interest" then it will be added "Product Editor." Within the "Product Editor" a user can add products to a display which, after saving, updates the maps throughout the retail store.</p>
          <p>Different product display types are only differentiated by shape and size to reflect the floor plan from a glance. The "You Are Here" display type is synced with maps by naming the displays with the parameters used in the URL of each map. </p>
        </React.Fragment>
      )
    }
  }

  export default DisplayAdmin;
