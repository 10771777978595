import React, { Component } from 'react';
import headshot from './Headshot.png';
import resume from './Micah-Robinson-Resume-Print.pdf';
import resumeimg from './Resume.png';
import companies from './Companies.jpg';
import './resume.css';

class Resume extends Component {
  constructor() {
    super();
    this.state= {
      proslist: ['[ Background in Design ]', '[ User Advocate ]', '[ Relentlessly Learning ]', '[ Skill Diversity ]'],
      prositem: '[ Background in Design ]',
      conslist: ["[ Slight Beard Dandruff (I'm working on it) ]", '[ Trouble Saying No ]'],
      consitem: "[ Slight Beard Dandruff (I'm working on it) ]"
    };
    this.changePro= this.changePro.bind(this);
    this.changeCon= this.changeCon.bind(this);
  }
  changePro(oldItem) {
    const prosList= this.state.proslist;
    const prosItem= this.state.prositem;
    const currentIndex= prosList.indexOf(prosItem);
    let index= 0;
    (currentIndex === (prosList.length-1)) ? index= 0 : index= currentIndex+1;
    this.setState({prositem : prosList[index]});
  }
  changeCon(oldItem) {
    const consItems= this.state.conslist;
    let index= Math.floor(Math.random()*consItems.length);
    while(consItems[index] === oldItem){
      index= Math.floor(Math.random()*consItems.length);
    }
    this.setState({consitem : consItems[index]});
  }

  render() {
    return (
      <div className="resume">
        <div>
          <img src={headshot} className="face" alt="Face of Micah Robinson" />
          <p><span id="titleName">Micah Robinson</span> is currently living and working in Seattle, WA, continuing to pursue a remote career in web development.
          He has a bachelor’s degree in Graphic Design and five years experience in front-end web development, making him an adaptable and very capable team&nbsp;member.</p>
          <p>Micah is passionate about designing and coding, in an elegant and efficient manner, new ways to create enjoyable experiences for users.
          Outside of that, he loves spending time with his dog, <a target="_blank" rel="noopener noreferrer" aria-describedby="new-window-0" href="https://www.instagram.com/p/B1ZQe6THjOx/?utm_source=ig_web_button_share_sheet">Kona</a>, and running around his neighborhood while listening to&nbsp;podcasts.</p>
          <p>If you'd like to learn more about Micah, feel free to reach out in email <a target="_blank" rel="noopener noreferrer" aria-describedby="new-window-0" href="mailto:micahrobinsondesigns@gmail.com">micahrobinsondesigns@gmail.com</a> or on social media @micahrobinsondesigns.</p>
          <p><strong>Pros:</strong> <span className="currently" onClick={this.changePro.bind(this, this.state.prositem)}>{this.state.prositem}</span></p>
          <p><strong>Cons:</strong> <span className="currently" onClick={this.changeCon.bind(this, this.state.consitem)}>{this.state.consitem}</span></p>
        </div>
        <div className="resumeImages">
        <a target="_blank" aria-describedby="new-window-0" rel="noopener noreferrer" href={resume}><img className="resumeImg" alt="Resume Download" src={resumeimg} /></a>
        <div className="stickyNote">
          <ul>
          <li>Wordpress work:</li>
          <li><a target="_blank" aria-describedby="new-window-0" rel="noopener noreferrer" href="http://flipmattress.com/">Flip Mattress</a></li>
          <li><a target="_blank" aria-describedby="new-window-0" rel="noopener noreferrer" href="http://bedroomsandmore.com/">Bedrooms & More</a></li>
          <li><a target="_blank" aria-describedby="new-window-0" rel="noopener noreferrer" href="https://45thstreetbedding.com/">45th Street Bedding</a></li>
          <li><a target="_blank" aria-describedby="new-window-0" rel="noopener noreferrer" href="https://newsroom.ocfl.net/">OCFL Newsroom</a></li>
          <li><a target="_blank" aria-describedby="new-window-0" rel="noopener noreferrer" href="https://gtdc.org/">GTDC</a></li>
          </ul>
        </div>
          <img className="companiesImg" alt="Companies I have worked with include T-Mobile, SAP, GTDC, AIGA, and Committee for Children" src={companies} />
        </div>
      </div>

    );
  }
}

export default Resume;
