import React, { Component } from 'react';
import Logo from '../Logo/logo.js';
import Scroll from '../../images/Scroll-Down.gif';
import Scrolled from '../../images/Scrolled-Down.png';

class HomeNav extends Component {
  constructor() {
    super();
    this.state= {
      width: window.innerWidth,
    };
  }
  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    window.addEventListener('scroll', this.handleScroll);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleWindowSizeChange= () => {
    this.setState({ width: window.innerWidth });
  };

  handleScroll= (event) => {
    let scrollTop= window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    const el= document.querySelector('.scrollDown');
    const newEl= document.createElement('img');
    if(scrollTop > 600 && el){
      el.parentNode.removeChild(el);
    } else if(scrollTop < 600 && !el && !document.querySelector('.scrolledDown')) {
      newEl.classList.add('scrolledDown');
      newEl.setAttribute('src', Scrolled);
      document.querySelector('.top-paper').appendChild(newEl);
    }
  };

  render() {
    const { width }= this.state;
    const isMobile= width < 1200;
    // the rest is the same...

    if (isMobile) {
      return (
        <React.Fragment>
          <Logo />
          <img className="scrollDown" src={Scroll} alt="Scroll Down" title="Scroll Down" />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Logo />
          <img className="scrollDown" src={Scroll} alt="Scroll Down" title="Scroll Down" />
        </React.Fragment>
      );
    }
  }
}

export default HomeNav;
