import React, { Component } from 'react';
import Drawer from 'react-drag-drawer'
import Template from './template.js';
import './drawer.css';


class ProjectDrawer extends Component {
  constructor() {
    super();
    this.state= {
      regular: true,
    };
  }

  componentWillMount(){
      document.body.style.overflow = "hidden"
  }

  componentWillUnmount(){
      document.body.style.overflow = null
  }

  toggle = (type, value) => event => {
    this.setState(state => {
      return {
        [type]: value
      };
    });
    let openState = this.props.openState;
    setTimeout(function(){
      openState(false)
    }, 500)
  };

  render () {
    const productObject = this.props.project;
    if(this.props.open){
      this.toggle("regular", true);
    }
    let projectTitle= '';
    let projectRelated= '';
    switch(productObject) {
      case 'phpPlugins':
        projectTitle= 'PHP Plugins';
        projectRelated= ['anyDecision', 'ocfl'];
      break;
      case 'maps':
        projectTitle= 'Maps';
        projectRelated= ['digitalPriceTags', 'displayAdmin'];
      break;
      case 'digitalPriceTags':
        projectTitle= 'Digital Price Tags';
        projectRelated= ['anyDecision', 'displayAdmin'];
      break;
      case 'displayAdmin':
        projectTitle= 'Display Admin';
        projectRelated= ['maps', 'digitalPriceTags'];
      break;
      case 'anyDecision':
        projectTitle= 'Any Decision';
        projectRelated= ['phpPlugins', 'ocfl'];
      break;
      case 'ocfl':
        projectTitle= 'OCFL';
        projectRelated= ['phpPlugins', 'maps'];
      break;
      default:
        projectTitle= 'PHP Plugins';
      break;
    }
    const {
      regular
    } = this.state;

    return (
      <div>
      <Drawer
        open={regular}
        onRequestClose={this.toggle("regular", false)}
        modalElementClass="modalClass"
      >
        <div className="drawerBack">
        <div className="closeButton" aria-label="Close" aria-describedby="drawerClose" onClick={this.toggle("regular", false)}>
          ×
        </div>
          <Template title={projectTitle} related={projectRelated} />
        </div>
      </Drawer>
      </div>
    )
  }
}

export default ProjectDrawer;
