import React, { Component } from 'react';
import linkedin from './LinkedIn_IMG.png';
import instagram from './Instagram_IMG.png';
import github from './Github_IMG.png';
import dribbble from './Dribbble_IMG.png';

class Social extends Component {
  render() {
    return (
      <div className="social-link-wrap">
        <a href="https://github.com/micahrobinsondesigns" target="_blank" aria-describedby="new-window-0" rel="noopener noreferrer"><img src={github} className="social-link" alt="Github Profile" /></a>
        <a href="https://dribbble.com/micahrobinsondesigns" target="_blank" aria-describedby="new-window-0" rel="noopener noreferrer"><img src={dribbble} className="social-link" alt="Dribbble Profile" /></a>
        <a href="https://linkedin.com/in/micahrobinsondesigns/" target="_blank" aria-describedby="new-window-0" rel="noopener noreferrer"><img src={linkedin} className="social-link" alt="LinkedIn Profile" /></a>
        <a href="https://www.instagram.com/micahrobinsondesigns/" target="_blank" aria-describedby="new-window-0" rel="noopener noreferrer"><img src={instagram} className="social-link" alt="Instagram Profile" /></a>
      </div>
    );
  }
}

export default Social;
