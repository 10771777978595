import React, { Component } from 'react';

class PHPPlugins extends Component {

    render () {
      return (
        <React.Fragment>
          <h3>Blog Search Widget <a target='_blank' rel="noopener noreferrer" href='https://github.com/micahrobinsondesigns/Woocommerce-Blog-Search-Widget'>See on GitHub</a></h3>
          <p><strong>Need:</strong> The default search bar on WooCommerce websites will search through the content of blog posts, pages, and product details. There is not a great way to search just one of those webpage types.</p>
          <p><strong>Solution:</strong> This WooCommerce Plugin was developed to create a widget that will only search within blog posts for content. It was intended to be used in the sidebar of a blog, but it will work anywhere a widget is allowed. The default WordPress search form was copied so that this search form will dynamically change with any chosen theme. The widget also includes a customizable Title and Placeholder.</p>

          <h3>Tag Chips <a target='_blank' rel="noopener noreferrer" href='https://github.com/micahrobinsondesigns/Woocommerce-Tag-Chips'>See on GitHub</a></h3>
          <p><strong>Need:</strong> Custom information for a group of products to quickly display.</p>
          <p><strong>Solution:</strong> This plugin creates the ability to add custom "chips" for product pages using WooCommerce Product Tags. Every product that is tagged will display the customized chip associated with it. With this plugin a WooCommerce admin is able to create a chip with a brand new tag or edit a previously existing tag to include a chip. The text of the chip is customizable along with the color of the chip and the text in the chip. To make coloring easier the # at the beginning of a hex color is generated automatically in JavaScript depending on what the user types in the form fields.</p>

          <h3>Dimensions Tab <a target='_blank' rel="noopener noreferrer" href='https://github.com/micahrobinsondesigns/WooCommerce-Dimensions-Tab'>See on GitHub</a></h3>
          <p><strong>Need:</strong> WooCommerce does not show the dimensions of individual variations by default.</p>
          <p><strong>Solution:</strong> This WooCommerce plugin hides simple product default dimensions and weight from the Additional Information tab. With the custom attribute "Size" and dimensions and weight added to variations using that attribute the dimensions and weight will be listed next to the variation in the Additional Information tab or a new tab with the title Dimensions if there are no attributes with "Visible on the product page" checked.</p>

          <h3>Category Disclaimer <a target='_blank' rel="noopener noreferrer" href='https://github.com/micahrobinsondesigns/Woocommerce-Category-Disclaimer'>See on GitHub</a></h3>
          <p><strong>Need:</strong> WooCommerce does that have a way of applying a disclaimer or important information that applies to a group of products without copying and pasting across a whole category of products in each one’s short description.</p>
          <p><strong>Solution:</strong> This plugin can be used to create disclaimers or notes that a user would want to add to the end of the short description of every product in a category. The "disclaimer" is italicized to separate it from the rest of the content visually and the content can include html tags.</p>

          <h3>WordPress Link Shortcode</h3>
          <p><strong>Need:</strong> Make links dynamic to avoid breaking links or, at least, making them less susceptible to "link rot".</p>
          <p><strong>Solution:</strong> Utilizing jQuery UI for a modal, this plugin makes a form with options in order to add a shortcode to a product description without needing to remember what the shortcode and its options are. The shortcode also utulizes AJAX calls when a product previews on a category page.</p>

        </React.Fragment>
      )
    }
  }

  export default PHPPlugins;
