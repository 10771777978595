import React, { Component } from 'react';
import ProjectDrawer from '../ProjectDrawer/drawer'

import './project.css';
import displaynavmob from './Display_Admin_IMG-Mobile.png';
import tagsnavmob from './Price_Tags_IMG-Mobile.png';
import mapsnavmob from './Maps_IMG-Mobile.png';
import ocflnavmob from './OCFL_IMG-Mobile.png';
import phpnavmob from './PHP_Plugins_IMG-Mobile.png';
import anynavmob from './Any_Decision_IMG-Mobile.png';

class Tags extends Component {
  constructor() {
    super();
    this.state= {
      selected: ''
    };
  }
  setFilter(filter) {
    this.setState({selected : filter})
    this.props.onChangeFilter(filter);
  }
  isActive(value) {
    return (
      (value===this.state.selected) ?'active':'default'
    );
  }
  render() {
    return (
      <ul className="project-link-wrap">
        <li className={this.isActive('')} onClick={this.setFilter.bind(this, '')}>[ HTML & CSS ]</li>
        <li className={this.isActive('php')} onClick={this.setFilter.bind(this, 'php')}>[ php ]</li>
        <li className={this.isActive('jQuery')} onClick={this.setFilter.bind(this, 'jQuery')}>[ jQuery ]</li>
        <li className={this.isActive('Framework7')} onClick={this.setFilter.bind(this, 'Framework7')}>[ Framework7 ]</li>
     </ul>
    );
  }
};

class ProjectItem extends Component {
  openDrawer(e) {
    this.props.project(e.target.id)
  }
  render() {
    return (
      <li
      className="project"
      value={this.props.name}
      id={this.props.id}
      style={{backgroundImage: "url("+this.props.image+")"}}
      onClick={(e) => this.openDrawer(e)}>
      </li>
    );
  }
};

class List extends Component {
  constructor(props) {
    super(props);
    this.state= {
      filter: '',
      showDrawer: false,
      projectId: ''
    };
    this.changeFilter = this.changeFilter.bind(this);
    this.setProject = this.setProject.bind(this);
    this.closeProject = this.closeProject.bind(this);
  }
  changeFilter(filter) {
    this.setState({
      filter: filter
    });
    let notList = this.props.Data;
    let filterist = this.props.Data;
    if (filter !== '') {
      filterist = filterist.filter((i)=> i.tags.indexOf(filter) !== -1);
      notList = notList.filter((i)=> i.tags.indexOf(filter) === -1);
    } else {
      notList = [];
    }
    notList.map(function(notItem) {
      let el = document.querySelector('[value="'+notItem.name+'"]');
      return (el.classList.add('hide'), el.classList.remove('show'), el.style.pointerEvents = "none");
    });
    filterist.map(function(item) {
      let el = document.querySelector('[value="'+item.name+'"]');
      return (el.classList.add('show'), el.classList.remove('hide'), el.style.pointerEvents = "");
    });
  }
  setProject(projectId){
    this.setState({
      projectId,
      showDrawer: true
    });
  }
  closeProject(response){
    this.setState({
      showDrawer: response
    });
  }
  render() {
    let filterList = this.props.Data;
    let projectProp = this.setProject;
    filterList = filterList.map(function(Props, index) {
      return <ProjectItem key={index} project={(e) => projectProp(e)} {...Props} />
    });
    let drawer = this.state.showDrawer ? <ProjectDrawer project={this.state.projectId} openState={this.closeProject} /> : ''
    return (
      <div>
      <Tags onChangeFilter={this.changeFilter}/>
      <ul className="projectList">
        {filterList}
      </ul>
      {drawer}
      </div>

    );
  }
};

const options = [
  {
    name: 'PHP Plugins',
    id: 'phpPlugins',
    image: phpnavmob,
    tags: ['php']
  },{
    name: 'Maps',
    id: 'maps',
    image: mapsnavmob,
    tags: ['jQuery']
  },{
    name: 'Digital Price Tags',
    id: 'digitalPriceTags',
    image: tagsnavmob,
    tags: ['Framework7']
  },{
    name: 'Display Admin',
    id: 'displayAdmin',
    image: displaynavmob,
    tags: ['Framework7']
  },{
    name: 'Any Decision',
    id: 'anyDecision',
    image: anynavmob,
    tags: ['jQuery']
  },{
    name: 'OCFL',
    id: 'ocfl',
    image: ocflnavmob,
    tags: ['jQuery']
  }];


class ProjectFilter extends Component {
  render() {
    return (
      <React.Fragment>
        <List Data={options} />
      </React.Fragment>
    );
  }
}

export default ProjectFilter;
