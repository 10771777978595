import React, { Component } from 'react';
import OldTags from './Old-Paper-Tags.jpg';
import NewTags from './New-Paper-Tags.jpg';
import FirstDigMock from './First-Digital-Mattress-Tag.jpg';
import SecondDigMock from './Second-Digital-Mattress-Tag.jpg';
import ThirdDigMock from './Third-Digital-Mattress-Tag.jpg';
import FourthDigMock from './Fourth-Digital-Mattress-Tag.jpg';
import MattressPricetag from '../../../videos/Mattress-Price-Tag.mp4';
import ThreeVerticalDigMock from './Digital-Price-Tag-Three-Vertical.jpg';
import FourHorizontalDigMock from './Digital-Price-Tag-Four-Horizontal.jpg';
import FinalDigTag from './Final-Price-Tag.jpg';

import Photograph from '../../Photograph/photograph.js';
import VideoPlayer from '../../VideoPlayer/video.js';

class PriceTags extends Component {

    render () {
      return (
        <React.Fragment>
          <p><strong>Need:</strong> Paper price tags were redesigned, however printing them each time the price changed was costly and time consuming.</p>
          <p><strong>Solution:</strong> Utilizing Amazon Fire tablets in conjunction with a custom web app written in Framework7 (using VueJS) to display dynamic pricing, images/videos, and descriptions.</p>
          <p><strong>Process:</strong></p>
          <Photograph url={OldTags} rotate="-1" caption="Old Mattress Price Tag" />
          <Photograph url={NewTags} rotate="1" caption="New Mattress Price Tag" />
          <p>With the new tags designed a digital price tag layout was needed to match the new style.</p>
          <Photograph url={FirstDigMock} rotate="1.5" caption="First Draft Digital Mattress Price Tag Mockup" />
          <Photograph url={SecondDigMock} rotate="-1" caption="Second Draft Digital Mattress Price Tag Mockup" />
          <p>Being that the screens were interactive, the pricing could be large and discoverable rather than small and illegible.</p>
          <Photograph url={ThirdDigMock} rotate="-1" caption="Third Draft Digital Mattress Price Tag Mockup" />
          <Photograph url={FourthDigMock} rotate="2" caption="Fourth Draft Digital Mattress Price Tag Mockup" />
          <p>Below is the final digital price tag for the Woodlawn Firm Latex Mattress. Notice how the price changes as the sizes flip through which was made by customizing a vertical slider.</p>
          <VideoPlayer src={MattressPricetag} />
          <p>In addition to the mattress price tags, the other products needed to be displayed in groups. The tablets could also be oriented horizontally or vertically, so both were accomodated in the mockups.</p>
          <Photograph url={ThreeVerticalDigMock} rotate="1" caption="Three Products in Vertical Digital Price Tag Mockup" />
          <Photograph url={FourHorizontalDigMock} rotate="-1" caption="Four Products in Horizontal Digital Price Tag Mockup" />
          <p>With a lot of flexbox, and utilizing an in house Rest API for the images and data, here is one of the many price tags on the main showroom floor.</p>
          <Photograph url={FinalDigTag} rotate="-1" caption="Flowery Bed Set Price Tag" />
          <p>Tapping on any of the products opens a dialog that shows more images and gives details about the variable pricing. The dialog also includes descriptions written on our website about the products as well as dimensions and any additional details.</p>
        </React.Fragment>
      )
    }
  }

  export default PriceTags;
