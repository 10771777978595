import React, { Component } from 'react';
import logo from './Logo.png';

class Logo extends Component {
  render() {
    return (
      <img src={logo} className="mrd-logo" alt="Micah Robinson Designs Logo" />
    );
  }
}

export default Logo;
