import React, { Component } from 'react';
import ReactGA from 'react-ga';

import HomeNav from './components/HomeNav/homenav.js';
import Social from './components/SocialLinks/social.js';
import Resume from './components/Resume/resume.js';
import ProjectFilter from './components/Projects/projectFilter.js';
import './App.css';

const trackingId = "UA-89547130-1";
ReactGA.initialize(trackingId);

class App extends Component {
  constructor() {
    super();
    this.state= {
      list: ['[ Written in ReactJS ]', '[ Always Under Construction ]', '[ Hosted on Github ]', '[ an Array of M\'s ]', '[ Writing New Things as You Click ]', '[ Wants to Solve All of Your Problems ]'],
      item: '[ Written in ReactJS ]',
      start: true,
      end: false
    };
    this.changeItem = this.changeItem.bind(this);
  }
  changeItem(oldItem) {
    this.setState({item : oldItem});
    this.setState({start: false});
    if(this.state.list.indexOf(oldItem) === (this.state.list.length-1)){
      this.setState({end: true});
    }
  }
  render() {
    const items= this.state.list;
    const item= this.state.item;
    let currently= '';
    let currentlyClass= '';
    let currentlyRotate= '-1';
    let index= 0;
    const currentIndex= items.indexOf(item);

    if(this.state.start){
      currently= items[0];
      currentlyClass= 'currently';
    } else if(this.state.end){
      currently= '[ M ]';
      currentlyClass= 'endCurrently';
    } else {
      index= currentIndex+1;
      currently= items[index];
      currentlyClass= 'currently';
      currentlyRotate= Math.floor(Math.random() * (5 - 1)) + 1;
    }

    return (
		<div className="App">
			<header className="App-header">
				<div className="top-paper" alt="paper background">
					<HomeNav />
				</div>
				<aside className="headline-content">MicahRobinson.Dev is <span className={currentlyClass} onClick={this.changeItem.bind(this, currently)} style={{transform: 'rotate(' + currentlyRotate + 'deg)'}}>{currently}</span></aside>
			</header>
      <section className="project-wrap">
        <ProjectFilter />
      </section>
      <section className="mid-paper">
        <Resume />
      </section>
      <footer className="bottom-area">
        <Social />
        <p>Micah Robinson © 2022</p>
      </footer>
		</div>
    );
  }
}

export default App;
